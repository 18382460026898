.App {
	text-align: center;
	width: 100%;
}

.App-header {
  background-color: #c2cbd1;
  min-height: 6vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.leftGutter {
  width: 20%;
  display: inline-block;
}

.contentBody {
  width: 100%;
  max-width: 70vw;
  font-size: 20px;
  line-height: 1.3;
  justify-content: center;
  align-items: center;
}

.contentBodyContainer {
  justify-content: center;
  width: 100%;
  display: flex;
}

.articleCardContainer {
	margin-top: 15px;
}

.sideContent {
  width: 20%;
  display: inline-block;
}

.articleDisplay {
  text-align: left;
}

.navigationText {
  flex-grow: 1;
  padding: 15px;
  display: flex;
}

.navigationLink {
	text-decoration: none;
	color: black;
	align: right;
	color: #386390;
}

.navigationLink:visited {
	color: #386390;
}

.navigationLinkDropdown {
	text-decoration: none;
	color: black;
}

.navigationLinkDropdown:visited {
	text-decoration: none;
	color: black;
}

.navigationLogoContainer {
	display: flex;
	flex-grow: 1;
}

.navigatinoPrimaryLink {
	align: right;
}

.navigationMenuContainer {
}

.navigationLogoLink {
	text-decoration: none;
}

* {
	font-family: 'Gruppo', cursive;
}

.navigationLogoText {
	font-family: 'Gruppo', cursive;
	font-size: 2em;
	font-weight: 650;
	color: #386390;
}

.navigationLogoLink:visited {
	color: black;
}

.articleCard {
	width: 80%;
	min-width: 400px;
	max-width: 800px;
	height: 200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 8px;
	margin-bottom: 8px;
	display: block;
	text-align: left;
}

.articleCardSkeleton {
	width: 80%;
	min-width: 460px;
	max-width: 800px;
	height: 280px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 8px;
	margin-bottom: 8px;
	display: block;
	text-align: left;
}

.cardLink {
	text-decoration: none;
	color: black;
}

.articleTag {
	margin: 4px;
	color: blue;
}

.articleTitle {
	text-align: left;
	margin-bottom: 2px;
}

.articleDate {
	color: gray;
	font-size: 14px;
	text-align: left;
}

.articleTagContainer {
	text-align: left;
}

.footerLink {
	text-decoration: none;
	color: black;
}

.footerLink:visited {
	color: black,
}

.footerText {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	min-height: 48px;
}

.footerTextLeft {
	text-align: left;
	min-height: 48px;
	min-width: 48px;
	margin-left: 8px;
}

.footerTextRight {
	text-align: right;
	min-height: 48px;
	min-width: 48px;
}

.aboutText {
	text-align: left;
	font-size: 20px;
}

.articleMarkdown {
	text-align: left;
}

.crossPostedText > p {
	font-size: 12px;
	margin: 0px;
	padding: 0px;
}

.crossPostedText > a {
	font-size: 12px;
	margin: 0px;
	padding: 0px;
}

.crossPostedText {
	text-align: left;
	margin-bottom: 4px;
	margin-top: 4px;
	padding: 0px;
	line-height: 0.6;
}

.fetchMoreButton {
	border-radius: 20px;
	margin-top: 16px;
	background-color: #9FE2BF;
	color: black;
}

.articleCardTitle {
	margin-top: 10px;
	font-size: 1.8em;
}

.footerGridContainer {
	bottom: 0px;
	position: absolute;
	width: 100%;
	padding-top: 15px;
	background-color: #D1F1F8;
	margin-top: 20px;
	min-height: 200px;
}

.footerGridItem {
}

.footerGridItemRight {
	padding-right: 30px;
}

.footerGridItemLeft {
	text-align: left;
}

.navigationAllTopics {
	color: #386390;
	font-size: 18px;
	font-weight: 500;
}

.navigationContainer {
	margin-top: 6px;
	margin-bottom: 4px;
}

.navigationAppBar {
	background-color: #000000;
}

.privacyPolicy {
	text-align: left;
}

.contactUsForm {
	padding-top: 40px;
}

.contactUsFormField {
	padding-top: 7px;
	padding-bottom: 7px;
	min-width: 60%;
	width: 60%;
}

.contactUsSubmitButton {
	padding: 10px;
	border-radius: 20px;
	margin-top: 20px;
	background-color: white;
	color: black;
}

.landingIntroText {
	font-size: 18px;
	text-align: left;
}

.landingIntroTextContainer {
	padding-top: 28px;
	padding-bottom: 16px;
}

.cookieConsentBanner {
	background-color: #9FE2BF;
	width: 100%;
	min-height: 100px;
	bottom: 0px;
	position: fixed;
	z-index: 100;
}

.cookieConsentBannerButton {
	margin: 8px 12px 8px 12px;
	border-radius: 20px;
}

.cookieConsentBannerText {
	margin: 10px;
}

.articleCardContainer {
	min-height: 1200px;
}

.routerContainer {
	min-height: 2400px;
}

.articleCardViewPlaceholder {
	min-height: 2400px;
}

.mainLoadingDiv {
	min-height: 2400px;
}

.articleLoadingDiv {
	min-height: 2400px;
}

.articleQueryInputContainer {
	margin-top: 60px;
	margin-bottom: 38px;
}

.articleQueryInput {
	width: 60%;
	min-width: 180px;
	max-width: 400px;
}

.articleQueryInput fieldset {
	border-radius: 28px;
}

.webName {
	font-family: 'Gruppo', cursive;
	font-size: 4em;
	font-weight: 'bold';
	color: #386390;
}


.articleQuerySubmitButton {
	margin-bottom: 30px;
}

.appContainer {
	position: relative;
	min-height: 100vh;
}

.divHolder {
	height: 200px;
}

.loginText {
	margin: 25px;
	margin-top: 50px;
	color: #386390;
}

.loginField {
	margin: 15px;
}

.loginCotnainer {
	width: 250px;
}

.loginInputField {
	min-width: 250px;
}

.chatArea {
	width: 100%;
}

.waitlistContainer {
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: 'Quicksand';
}

.waitlistText {
	font-size: 24px;
	font-weight: 500;
	font-family: 'Quicksand';
}

.waitlistEntry {
	margin-top: 10px;
}

.waitlistTitle {
	font-size: 4em;
	font-weight: 500;
	font-family: 'Quicksand';
	margin: 10px;
}

.waitlistIntroText {
	font-size: 2em;
	font-weight: 550;
	font-family: 'Quicksand';
	margin-top: 15px;
	margin-bottom: 40px;
	text-align: 'left';
}

.listGridHolder {
	margin-top: 20px;
}

.waitlistJoinText {
	margin-top: 30px;
}